import styled from "@emotion/styled"
import Axios from "axios"
import React from "react"
import { BigPillButton } from "../../components/button"
import Card from "../../components/card"
import Input from "../../components/input"
import Link, { useNavigate } from "../../components/link"
import Typography from "../../components/typography"
import { FUNCTIONS_ENDPOINT } from "../../config"
import { useRoiContext } from "../../contexts/roiContext"
import colors from "../../styles/colors"
import Global from "../../styles/global"
import { trackError } from "../../utils/segment"
import SEO from "../../components/seo"
import { BackTo } from "../../components/backTo"

const ContentWrapper = styled.div`
  margin-top: 80px;
`
const Wrapper = styled.div`
  max-width: 20.5rem;
`

const ComplianceWrapper = styled.div`
  display: flex;
  margin-bottom: 2rem;
`

const Checkbox = styled.input`
  margin-right: 1rem;
  transform: scale(1.5);
  &:before {
    background-color: green;
  }
`

const RoiInputPage = () => {
  const {
    stepDone,
    companyName,
    productCategory,
    email,
    setCompanyName,
    setEmail,
    calcRoi,
    getQueryParamsArray,
  } = useRoiContext()
  const [isSending, setIsSending] = React.useState(false)
  const navigate = useNavigate()

  const onSubmit = React.useCallback(
    async e => {
      e.preventDefault()
      stepDone("MORE_INFO", { companyName, email })
      calcRoi()
      setIsSending(true)

      try {
        const response = await Axios.post(
          `${FUNCTIONS_ENDPOINT}/roi-calculator`,
          {
            email,
            companyName,
            from: "roi-details",
            data: {
              productCategory,
              link: `https://www.mavenoid.com/en/roi/results/?data=${getQueryParamsArray().join(
                "_"
              )}`,
            },
          }
        )
        if (response.data.status === "success") {
          navigate("/roi-new/results/")
        } else {
          throw new Error(
            `Roi calculator request failed: ${JSON.stringify(
              response.data,
              null,
              2
            )}`
          )
        }
      } catch (e) {
        trackError(e)
        navigate("/roi-new/results/")
      } finally {
        setIsSending(false)
      }
    },
    [
      calcRoi,
      companyName,
      email,
      getQueryParamsArray,
      navigate,
      productCategory,
      stepDone,
    ]
  )

  return (
    <>
      <BackTo linkTo="roi" />
      <SEO title="details" enableCookiebot />
      <Global bg="primary" />
      <ContentWrapper>
        <Card center variant="primary">
          <form
            onSubmit={onSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="h2" as="h1" style={{ fontWeight: 700 }}>
              Enter your details to <br /> get your ROI estimate
            </Typography>
            <Wrapper>
              <Input
                placeholder="Company name"
                variant="white"
                style={{
                  width: "100%",
                  marginTop: "2rem",
                }}
                onChange={e => setCompanyName(e.target.value)}
                defaultValue={companyName}
              />
              <Input
                placeholder="Work email"
                variant="white"
                style={{
                  width: "100%",
                  marginBottom: "2rem",
                }}
                onChange={e => setEmail(e.target.value)}
                defaultValue={email}
                type="email"
                required
              />
              <ComplianceWrapper>
                <Checkbox type="checkbox" required />
                <Typography variant="caption" style={{ textAlign: "left" }}>
                  I agree to share the information with Mavenoid. Check our{" "}
                  <Link
                    to="/privacy-policy/"
                    forceLanguage="en"
                    underlined
                    style={{ color: colors.white }}
                  >
                    Privacy Policy
                  </Link>{" "}
                  for more information.
                </Typography>
              </ComplianceWrapper>
              <BigPillButton
                onClick={() => {
                  stepDone("MORE_INFO", {
                    companyName,
                    email,
                  })
                }}
                variant="white"
                style={{
                  width: "100%",
                  height: "90px",
                  outline: "none",
                  padding: 0,
                }}
                type="submit"
              >
                {isSending ? "Calculating..." : "Get my ROI"}
              </BigPillButton>
            </Wrapper>
          </form>
        </Card>
      </ContentWrapper>
    </>
  )
}

export default RoiInputPage
